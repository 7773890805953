.service-card {
    background: radial-gradient(#a5e3f0, white);
    /* background: radial-gradient(#f1f6f4, white); */
    /* transition: transform 0.3s ease-in-out; */
}
/* .service-card:hover {
    transform: translateY(-5px);
} */

.service-card:before {
    content: '';
    display: inline-block;
    width: 65px;
    height: 65px;
    position: absolute;
    border-radius: 20px 20px 20px 20px;
    transform: rotate(45deg);
    background: #fff;
    content: "";
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px #f4f6ff;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}
.service-card:hover:before {
    background: #44d6fe;
}

.service-card img{
    transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    -webkit-transition: all 400ms linear;
}
.service-card:hover img{
    transform: translateX(30px) translateY(-35px);
    -moz-transform: translateX(30px) translateY(-35px);
    -ms-transform: translateX(30px) translateY(-35px);
    -webkit-transform: translateX(30px) translateY(-35px);
}

.service-card:hover img {
    position: relative;
}

