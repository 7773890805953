/* CSS for Hero component */
.inter{
  font-family: "Inter", sans-serif;
}

.slide-text h2 {
  line-height: 1.3;
}

.deca{
  font-family: "Lexend Deca", sans-serif;
}

.slick-dots li button:before {
  font-size: 14px;
  line-height: 22px;
  color: #00304e !important;
}

.slider-bg{
  background: linear-gradient(264deg, rgb(244 251 250 / 74%), rgb(239 249 237 / 96%), rgb(255 255 255 / 95%)),  
  url('/src/assets/images/bg-0.webp');
background-position: center;
background-size: cover;
}

li.slick-active {
  background: #cbebff;
  line-height: 24px;
  border-radius: 50%;
}
.container {
  max-width: 1140px;
}

/* Styling for individual sections */
.section {
  width: 100%;
  margin-bottom: 20px;
}

.section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.section p {
  font-size: 16px;
  color: #cccccc;
}
.top-bar:before {
  content: '.';
  background: linear-gradient(179deg, #ffffff, #ffffffd6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.top-bar{
  background: url('/src/assets/images/bg-1.jpeg');
  background-position: center;
  background-size: cover;
}
.shape {
  animation: MoveUpDown 4s linear infinite;
}
@keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: -60px;
  }
}
.shape1 {
  animation: MoveDownUp 4s linear infinite;
}
@keyframes MoveDownUp {
  0%, 100% {
    top: 30;
  }
  50% {
    top: -30px;
  }
}
/* Adjustments for responsiveness */
@media (min-width: 768px) {
  .section {
    width: calc(33.333% - 20px);
  }
}



